import { module } from 'modujs';

import { Loader } from "@googlemaps/js-api-loader"
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
                closeInfowindow: 'closeInfowindow',
                toggleActive: 'toggleActive',
            },
        }

        this.map = false;

        this.map_icon = {
            anchor: new google.maps.Point(26, 52),
            size: new google.maps.Size(52,52),
            url: `/app/themes/trinity/public/images/map-pin.svg`
        };

        this.map_icon_active = {
            anchor: new google.maps.Point(26, 52),
            size: new google.maps.Size(52,52),
            url: `/app/themes/trinity/public/images/map-pin-active.svg`
        };
    }

    init() {
        const $this = this;
        
        const loader = new Loader({
            apiKey: "AIzaSyBExhvzxkLYzPMk3U37ofGT57XZ2VGAhNk",
            version: "weekly",
        });

        const el = this.$('map');

        setTimeout(() => {
            loader.load().then(() => {
                $this.map = this.initMap($(el))
            });
        }, 500);
        document.addEventListener("googlemaps_loaded", function() {
            setTimeout(() => {
                loader.load().then(() => {
                    $this.map = this.initMap($(el))
                });
            }, 500);
        });

        setTimeout(() => {
            if (!$this.map) {
                $(el).children('.message').text("Contenu désactivé pour respecter vos préférences de gestion des cookies.");
            }
        }, 2000)
    }
    
    closeInfowindow(e) {
        $('.c-map_container').removeClass('is-open');
        this.currentInfowindow.close();
        this.centerMap(this.map);
    }

    updateMap(categories) {
        let markers = this.map.markers;
        const map = this.map;

        if(markers) {
            markers.forEach(marker => {
                marker.setVisible(true);
            })
            this.centerMap(this.map);
        }


        // const markerCluster = new MarkerClusterer({ map, markers });

    }

    initMap( $el ) {
        $('.c-map_wrap').removeClass('is-hidden');
        let t = this;
        // Find marker elements within map.
        var $markers = $el.find('.marker');
    
        var styles = [
            {
                "featureType": "administrative",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "administrative",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#444444"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#F2F2F2"
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry.fill",
                "stylers": [
                    {
                        "color": "#FFFFFF"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "all",
                "stylers": [
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 45
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#CEA8D5"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "visibility": "on"
                    },
                    {
                        "color": "#2D1633"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#FFFFFF"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#FFFFFF"
                    }
                ]
            },
            {
                "featureType": "road",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text",
                "stylers": [
                    {
                        "color": "#F6EEEE"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "labels.text.stroke",
                "stylers": [
                    {
                        "color": "#FFFFFF"
                    }
                ]
            },
            {
                "featureType": "road.highway.controlled_access",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "simplified"
                    }
                ]
            },
            {
                "featureType": "road.highway.controlled_access",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#FF0000"
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "labels.text.fill",
                "stylers": [
                    {
                        "color": "#FF0000"
                    },
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "all",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "color": "#46BCEC"
                    },
                    {
                        "visibility": "on"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "geometry",
                "stylers": [
                    {
                        "color": "#D2CEEE"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "labels",
                "stylers": [
                    {
                        "color": "#2D1633"
                    }
                ]
            }
        ]
    
        // Create gerenic map.
        var mapArgs = {
            zoom        : $el.data('zoom') || 8,
            mapTypeId   : google.maps.MapTypeId.ROADMAP,
            styles: styles,
            maxZoom: 15,
            center: new google.maps.LatLng(46.160329, -1.151139),
        };
        var map = new google.maps.Map( $el[0], mapArgs );
    
        // Add markers.
        map.markers = [];
        $markers.each(function(){
            t.initMarker( $(this), map );
        });
        
    
        // Center map based on markers.
        t.centerMap( map );
    
        // Return map instance.
        return map;
    }

    removeCustomMarker() {
        if (this.map && this.map.markers.length) {
            let oldMarker = this.map.markers.find(x => x.id === "adresseMarker");
            if (oldMarker) {
                oldMarker.setMap(null);
                this.map.markers.splice(this.map.markers.findIndex(item => item.id === "adresseMarker"), 1)
            } 
        }
    }

    addMarker ( marker ) {
        let couleur = '';
        if (marker.couleur) {
            couleur = marker.couleur;
        }

        let t = this;
        let map = this.map;

        const lat = marker.lat;
        const lng = marker.lng;
        const latLng = {
            lat: parseFloat( lat ),
            lng: parseFloat( lng )
        };


        const svgMarker = {
            anchor: new google.maps.Point(26, 52),
            size: new google.maps.Size(52,52),
            // url: `/app/themes/trinity/public/images/pin${couleur}.svg`
            url: `/app/themes/trinity/public/images/map-pin.svg`
        };


        var icon = svgMarker;
        // if(marker.icon) {
        //     icon = {
        //         url : marker.icon,
        //     };
        // }

        // Create marker instance.
        var markerMap = new google.maps.Marker({
            position : latLng,
            map: map,
            id: marker.id,
            icon: icon
        });
    
        // Append to reference for later use.
        map.markers.push( markerMap );
   
        google.maps.event.addListener(markerMap, 'click', function() {            
            t.openPopup(markerMap.id, marker.nom);
        });

        this.updateMap();
    }

    initMarkerAdresse( position ) {
        let t = this;
        let map = t.map;
        // Get position from marker.
        var latLng = {
            lat: parseFloat( position.lat ),
            lng: parseFloat( position.lng )
        };
    

        var icon = null;
    
        // Create marker instance.
        var marker = new google.maps.Marker({
            position : latLng,
            map: map,
            icon: icon,
            id: "adresseMarker"
        });
    
        // Append to reference for later use.
        map.markers.push( marker );
    
        google.maps.event.addListener(marker, 'click', function() {
            map.panTo(marker.getPosition());
        });
    }

    setMapOnAll(map) {
        for (let i = 0; i < this.map.markers.length; i++) {
            this.map.markers[i].setMap(map);
        }
    }


    removeMarkers ( ) {
        let t = this;
        let map = this.map;

        this.setMapOnAll(null);
        this.map.markers = [];
        this.updateMap();

    }

    initMarker( $marker, map ) {
        let t = this;
        // Get position from marker.
        var lat = $marker.data('lat');
        var lng = $marker.data('lng');
        var latLng = {
            lat: parseFloat( lat ),
            lng: parseFloat( lng )
        };
    
        var icon = null;
        if($marker.data('icon')) {
            icon = {
                url : $marker.data('icon'),
                scaledSize: new google.maps.Size(30, 30)
            };
        }
    
        // Create marker instance.
        var marker = new google.maps.Marker({
            position : latLng,
            map: map,
            id: $marker.data('id'),
            icon: icon
        });
    
        // Append to reference for later use.
        map.markers.push( marker );
   
        google.maps.event.addListener(marker, 'click', function() {            
            t.openPopup(marker.id, $marker.data('nom'));
        });
    }

    openPopup(idArg, nomArg){
        let t = this;
        let id = idArg;
        let nom = nomArg;
        
        
        if (
            typeof id === 'object' &&
            !Array.isArray(id) &&
            id !== null
            ) {
            nom = id.nom;
            id = id.id;
        }

        let marker = this.map.markers.find(x => x.id === id);

        if (marker && nom) {
            var infowindow = new google.maps.InfoWindow({
                content: nom
            });
            if( t.currentInfowindow ) {
                t.currentInfowindow.close();
            }
            infowindow.open( this.map, marker );
            t.currentInfowindow = infowindow;
    
            t.map.panTo(marker.getPosition());
        }

        if(marker && marker.id && $('.b-annuaire-cmp').length) {
            $('.c-card.-cmp').removeClass('is-active');
            $('.c-card.-cmp[data-id="'+marker.id+'"]').addClass('is-active');
            t.updateMarkers(id);
        }
    
        // $.ajax({
        //     url: '/wp/wp-admin/admin-ajax.php',
        //     type: 'POST',
        //     data: {action: 'map_infowindow', id: id},
        //     success: function( response ){
        //         $('.c-map_infowindow').html(response.content);
        //         $('.c-map_container').addClass('is-open');
        //         setTimeout(() => {
        //             t.call('update', false, 'Scroll'); // update Locomotive Scroll
        //         }, 1000);
        //     },
        // });
    }

    centerMap( map ) {

        // Create map boundaries from all map markers.
        var bounds = new google.maps.LatLngBounds();
        map.markers.forEach(function( marker ){
            bounds.extend({
                lat: marker.position.lat(),
                lng: marker.position.lng()
            });
        });
    
        // Case: Single marker.
        if( map.markers.length == 1 ){
            map.setCenter( bounds.getCenter() );
    
        // Case: Multiple markers.
        } else if (map.markers.length > 1) {
            map.fitBounds( bounds );
        } else if (map.markers.length == 0) {
            map.setCenter(new google.maps.LatLng(46.160329, -1.151139));
        }
    }

    toggleActive(e) {
        let el = e.currentTarget;
        let id = el.getAttribute('data-id');
        let t = this;

        $('.c-card.-cmp').removeClass('is-active');
        $(el).addClass('is-active');
        
        t.updateMarkers(id);
    }

    updateMarkers(id) {
        let markers = this.map.markers;
        let t = this;

        if(markers) {
            markers.forEach(marker => {
                if(marker.id == id) {
                    marker.setIcon(t.map_icon_active);
                    marker.setZIndex(2);
                }else {
                    marker.setIcon(t.map_icon);
                    marker.setZIndex(1);
                }
            })
        }
    }
}
