import { module } from 'modujs';
import 'photoswipe/dist/umd/photoswipe.umd.min.js';
import 'photoswipe/dist/umd/photoswipe-lightbox.umd.min.js';
import 'photoswipe/dist/photoswipe.css';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        var lightbox = new PhotoSwipeLightbox({
            gallery: this.el,
            children: 'a',
            showHideAnimationType: 'fade',
            // dynamic import is not supported in UMD version
            pswpModule: PhotoSwipe 
        });
        lightbox.init();
    }

}
