import { module as modular } from 'modujs';

export default class extends modular {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                btn: 'togglefilters',
                reset: 'reset',
                select: 'select',
                open: 'toggleSidebar',
                resetall: 'resetall',
                refresh: 'refresh',
                pagination: 'pagination',
                changeMonth: 'refreshMonth',
                reset: 'reset',
                checked: 'checked',
                change: 'change',
                toggleLetter: 'toggleLetter',
            },
            change: {
                change: 'refresh',
            },
            keydown: {
                enter: 'change',
            },
            keypress: {
                enter: 'enter',
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        if (this.getData('init')) {
            setTimeout(() => {
                this.refresh();
            }, 500);
        }
    }

    refresh() {
        let form = $(this.el);
        let t = this;
        jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: form.serialize(),
            success: function (response) {
                if (form.find('.b-relation_listing').length) {
                    form.find('.b-relation_listing').html(response.content);
                }

                if (form.find('.b-relation_pagination').length) {
                    form.find('.b-relation_pagination').html(response.pagination);
                }

                if (form.find('.b-relation_count').length) {
                    form.find('.b-relation_count').html(response.count);
                }

                // setTimeout(() => {
                //     t.call('update', false, 'Scroll'); // update Locomotive Scroll
                //     t.call('scrollTo', '.b-relation_listing', 'Scroll');
                // }, 500);
            },
        });
    }

    checked(e) {
        const target = e.currentTarget;
        const items = target;
        let check = false;

        for (let i = 0; i < 4; i++) {
            if (items[i].classList.contains('is-active')) {
                items[i].classList.remove('is-active');
                check == true;
            }
        }

        if (check !== true) {
            item.classList.add('-checked');
        }
    }

    pagination(e) {
        const el = e.currentTarget;
        let paged = el.getAttribute('data-paged');
        let form = $(this.el);
        $(form).find('input[name="paged"]').val(paged);
        $(form).find('input[name="pagination"]').val('true');

        this.refresh();
    }

    change() {
        let form = $(this.el);
        $(form).find('input[name="pagination"]').val('');

        this.refresh();
    }

    toggleLetter(e) {
        const el = e.currentTarget;
        const letter = el.getAttribute('data-letter');
        const items = this.$('toggleLetter');

        el.classList.toggle('is-active');
        if(el.classList.contains('is-active')) {
            $(this.el).find('input[name="letter"]').val(letter);
        }else {
            $(this.el).find('input[name="letter"]').val('');
        }

        items.forEach(element => { 
            if(element != el) {
                element.classList.remove('is-active');
            }
        });

        this.refresh();
    }

    enter(e) {
        if(e.key == 'Enter') {
            e.preventDefault();
            this.refresh();
        }
    }
}
