import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                close: 'close',
                toggleSubMenu: 'toggleSubMenu',
                toggleSub2Menu: 'toggleSub2Menu',
            }
        }
    }

    init() {

    }

    close() {
        const html = document.querySelector('html');
        const burger = document.querySelector('.c-header_nav_burger');
        const text = burger.querySelector('.-hover');
        html.classList.remove('has-navOpen');
        burger.classList.remove('is-active');
        text.textContent = text.dataset.texthover;
    }

    toggleSubMenu(e) {
        let current = e.currentTarget;
        let id = current.getAttribute('data-menuid');
        let targets = document.querySelectorAll('[data-menuid="'+ id +'"]');
        let defaults = document.querySelectorAll('.c-nav_submenu_item');
        let links = document.querySelectorAll('.c-nav_primary_container .c-nav_primary_link');
        targets.forEach((el) => {
            if(el.classList.contains('is-active')) {
                el.classList.remove('is-active');
                document.querySelector('.c-nav_secondary_container').classList.remove('is-active');
            }else {
                defaults.forEach((v) => {
                    v.classList.remove('is-active');
                });
                links.forEach((v) => {
                    if(v != current) {
                        v.classList.remove('is-active');
                    }
                });
                document.querySelector('.c-nav_secondary_container').classList.add('is-active');

                el.classList.add('is-active');
            }
        });

        let targets_mobile = document.querySelectorAll('[data-menu-mobile-id="'+ id +'"]');
        targets_mobile.forEach((el) => {
            if(el.classList.contains('is-active')) {
                el.classList.remove('is-active');
            }else {
                $('.c-nav_submenu_mobile').removeClass('is-active').css('height', '0px');
                el.classList.add('is-active');
            }

            if($(el).height()) {
                $(el).css('height', '0');
            }else {
                $(el).css('height', $('[data-id-sub-mobile="'+id+'"]').outerHeight()+'px');
            }
        });
    }

    toggleSub2Menu(e) {
        let current = e.currentTarget;
        let id = current.getAttribute('data-menuid');
        let targets = document.querySelectorAll('[data-menuid="'+id+'"]');
        targets.forEach((el) => {
            if(el.classList.contains('is-active')) {
                // el.classList.remove('is-active');
                $('[data-menuid="'+id+'"]').removeClass('is-active');
            }else {
                $('[data-nav="toggleSub2Menu"]').removeClass('is-active');
                $('.c-nav_submenu_item2').removeClass('is-active');
                $('.c-nav_submenu_item2_wrap').css('height', '0px');
                // el.classList.add('is-active');
                $('[data-menuid="'+id+'"]').addClass('is-active');
            }

            if($('[data-id-sub="'+id+'"]').height()) {
                $('[data-id-sub="'+id+'"]').css('height', '0');
            }else {
                $('[data-id-sub="'+id+'"]').css('height', $('[data-id-sub="'+id+'"]').find('.c-nav_submenu_item2').outerHeight()+'px');
            }
        });

        let targets_mobile = document.querySelectorAll('[data-menu-mobile-id="'+id+'"]');
        targets_mobile.forEach((el) => {
            if(el.classList.contains('is-active')) {
                el.classList.remove('is-active');
            }else {
                el.classList.add('is-active');
            }

            if($('[data-id-sub2-mobile="'+id+'"]').height()) {
                $('[data-id-sub2-mobile="'+id+'"]').css('height', '0');
            }else {
                $('[data-id-sub2-mobile="'+id+'"]').css('height', $('[data-id-sub2-mobile="'+id+'"]').find('.c-nav_submenu_item2').outerHeight()+'px');
            }
        });
        
        let parent = $(current).parents('.c-nav_submenu_mobile');
        if(parent) {
            setTimeout(() => {
                parent.css('height', parent.find('.c-nav_submenu_mobile_item').outerHeight()+'px');
            }, 1000);
        }
    }

}
