import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
            click: {
              toggle: 'toggle',
              close: 'close',
            }
          }
    }

    init() {

    }

    toggle() {
      let elements = document.querySelectorAll('.c-dropdown');
      let el = this.el;
      elements.forEach(element => {
        if(element !== el) {
          element.classList.remove('is-open');
        }
      });

      el.classList.toggle('is-open');
    }
    close() {
      this.el.classList.remove('is-open');
    }
}
