import { module as modular } from 'modujs';

export default class extends modular {
    constructor(m) {
        super(m);
        this.events = {
            click: {
                submit: 'select_commune',
            },
            change: {
                select_commune: 'select_commune',
                select_rue: 'select_rue',
            },
        };

        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
        this.communeSelect = $("#commune");
        this.rueSelect = $("#rue");
        this.ajaxRequest = null;
    }

    init() {
        this.call('updateMap', false, 'Map');
        this.select_commune();
    }

    select_commune() {
        const $this = this;
        let secteur = null;

        if ($this.rueSelect.val()) {
            secteur = $this.rueSelect.find(':checked').data('cmp-secteur');
        } else if ($this.communeSelect.val()) {
            secteur = $this.communeSelect.find(':checked').data('cmp-secteur');
        }

        if (this.ajaxRequest) {
            this.ajaxRequest.abort();
        }

        this.ajaxRequest = jQuery.ajax({
            url: this.ajaxurl,
            type: 'POST',
            data: {
                action: 'get_cmp',
                commune: $this.communeSelect.val() ?? null,
                rue: $this.rueSelect.val() ?? null,
                secteur: secteur ?? null,
            },
            success: function (response) {
                if (response.affich_rues) {
                    $('.flex_rues').show();
                    if (response.rues_options) {
                        $('.flex_rues select').html(response.rues_options);
                    }
                } else {
                    $('.flex_rues').hide();
                    $('.flex_rues select').html('');
                }

                $this.call('removeMarkers', false, 'Map');
                
                if (response.cmp_adultes) {
                    response.cmp_adultes.forEach((cmp) => {
                        if (cmp.geolocalisation) {
                            $this.call(
                                'addMarker',
                                {
                                    lat: cmp.geolocalisation.lat,
                                    lng: cmp.geolocalisation.lng,
                                    nom: cmp.post_title,
                                    id: cmp.ID,
                                    couleur: 'rouge',
                                },
                                'Map'
                            );
                        }
                    });
                }
                if (response.cmp_adolescents) {
                    response.cmp_adolescents.forEach((cmp) => {
                        if (cmp.geolocalisation) {
                            $this.call(
                                'addMarker',
                                {
                                    lat: cmp.geolocalisation.lat,
                                    lng: cmp.geolocalisation.lng,
                                    nom: cmp.post_title,
                                    id: cmp.ID,
                                    couleur: 'bleu'
                                },
                                'Map'
                            );
                        }
                    });
                }
                if (response.cmp_enfants) {
                    response.cmp_enfants.forEach((cmp) => {
                        if (cmp.geolocalisation) {
                            $this.call(
                                'addMarker',
                                {
                                    lat: cmp.geolocalisation.lat,
                                    lng: cmp.geolocalisation.lng,
                                    nom: cmp.post_title,
                                    id: cmp.ID,
                                    couleur: 'violet',
                                },
                                'Map'
                            );
                        }
                    });
                }

                // TODO: Afficher un marqueur spécifique sur le point de départ de la recherche
                // if (response.emplacement_recherche) {
                //     $this.call(
                //         'addMarker',
                //         {
                //             lat: response.emplacement_recherche.lat,
                //             lng: response.emplacement_recherche.lng,
                //             nom: $this.communeSelect.val(),
                //             id: $this.communeSelect.val(),
                //             couleur: 'violet',
                //         },
                //         'Map'
                //     );
                // }

                $('.wrapper_cards').html(response.cmp_html);
            },
        });

    }

    checked(e) {
        const target = e.currentTarget;
        const items = target;
        let check = false;

        for (let i = 0; i < 4; i++) {
            if (items[i].classList.contains('is-active')) {
                items[i].classList.remove('is-active');
                check == true;
            }
        }

        if (check !== true) {
            item.classList.add('-checked');
        }
    }
}
