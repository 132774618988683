import { module } from 'modujs';

export default class extends module {
    constructor(m) {
        super(m);

        this.events = {
          click: {
            toggle: 'toggle',
          }
        }
    }

    init() {
      
    }

    toggle(e) {
      const target = e.currentTarget;
      const section = this.parent('section', target);
      const theme = target.getAttribute('data-theme');
      const id_svg = target.getAttribute('data-id');

      const buttons = this.$('toggle');
      buttons.forEach((el) => {
        let el_theme = el.getAttribute('data-theme');
        if(el.classList.contains('u-bg-'+el_theme+'-primary')) {
          el.classList.remove('u-bg-'+el_theme+'-primary');
          el.querySelector('.c-button_icon').classList.remove('u-c-'+theme+'-primary');
          $('[data-location]').removeClass('is-active');
        }
      });
      
      if (section.classList.contains('is-active')) {
        section.classList.remove('is-active');
        target.classList.remove('u-bg-'+theme+'-primary');
        target.querySelector('.c-button_icon').classList.remove('u-c-'+theme+'-primary');

        // remove active svg point
        if(id_svg) {
          console.log('remove active svg');
          $('[data-location="'+id_svg+'"]').removeClass('is-active');
        }
      } else {
        const sections = this.$('section');
        sections.forEach(element => element.classList.remove('is-active')); // close section opened
        section.classList.add('is-active');
        target.classList.add('u-bg-'+theme+'-primary');
        target.querySelector('.c-button_icon').classList.add('u-c-'+theme+'-primary');

        // add active svg point
        if(id_svg) {
          console.log('add active svg', id_svg);
          $('[data-location="'+id_svg+'"]').addClass('is-active').addClass('u-c-'+theme+'-primary');
        }
      }
      this.call('update', false, 'Scroll'); // update Locomotive Scroll
    }
}
